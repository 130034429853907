<template>
  <div>
    <!-- Task Card Header -->
    <b-card class="card mb-1" title="Tambah Sekolah">
      <!-- Title -->
      <div class="d-flex pb-2 border-bottom">
        <b-avatar
          :src="blobImage"
          :text="avatarText('School')"
          :variant="`light-primary`"
          size="104px"
          rounded
        />
        <div class="ml-1 d-flex flex-column justify-content-center">
          <div class="d-flex mb-1">
            <input
              accept="image/png, image/jpg, image/jpeg"
              ref="file"
              type="file"
              style="display: none"
              @change="onFileChange"
            />
            <b-button
              variant="outline-primary small-gap-right"
              @click="$refs.file.click()"
            >
              Upload Foto Baru
            </b-button>
            <b-button @click="blobImage = ''" variant="secondary">
              Reset
            </b-button>
          </div>
          <span>Upload logo sekolah format jpg/png maks. 800kb</span>
        </div>
      </div>

      <!-- Classroom Config -->
      <div class="mt-2">
        <b-row>
          <!-- Nama Sekolah -->
          <b-col lg="6" class="mb-1">
            <label class="d-block">Nama Sekolah</label>
            <b-form-input
              v-model="formData.schoolName"
              placeholder="Masukkan Nama Sekolah"
            />
          </b-col>

          <!-- NPSN -->
          <b-col lg="6" class="mb-1">
            <label class="d-block">NPSN</label>
            <b-form-input
              v-model="formData.schoolNpsn"
              placeholder="Masukkan NPSN"
            />
          </b-col>

          <!-- Email Admin Sekolah -->
          <b-col lg="6" class="mb-1">
            <label class="d-block">Email Admin Sekolah</label>
            <b-form-input
              v-model="formData.adminEmail"
              name="adminEmail"
              type="email"
              placeholder="Masukkan Email Admin Sekolah"
            />
          </b-col>

          <!-- Nomor Telepon Sekolah -->
          <b-col lg="6" class="mb-1">
            <label class="d-block">Nomor Telepon</label>
            <b-form-input
              v-model="formData.schoolPhoneNumber"
              placeholder="Masukkan Nomor Telepon"
            />
          </b-col>

          <!-- Password Admin -->
          <b-col lg="6" class="mb-1">
            <label class="d-block">Password</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                :type="passwordFieldType"
                v-model="formData.adminPassword"
                placeholder="Masukkan Password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>

          <!-- Konfirmasi Password Admin -->
          <b-col lg="6" class="mb-1">
            <label class="d-block">Konfirmasi Password</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                :type="passwordFieldType"
                v-model="formData.adminConfirmPassword"
                placeholder="Masukkan Ulang Password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>

          <!-- Alamat Sekolah -->
          <b-col lg="12" class="mb-1">
            <label class="d-block">Alamat Sekolah</label>
            <b-form-textarea
              v-model="formData.schoolAddress"
              placeholder="Alamat Sekolah"
              rows="3"
              no-resize
            />
          </b-col>
        </b-row>
        <div>
          <b-button variant="primary" class="float-right" @click="submitForm">
            <div class="d-flex align-items-center ">
              <b-spinner
                v-show="isLoading"
                class="mr-1"
                variant="light"
                small
              />
              Simpan
            </div>
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
// Component
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BFormInput,
  BFormTextarea,
  BFormInvalidFeedback,
  BSpinner,
  BInputGroupAppend,
  BInputGroup,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// Utils
import { checkAuthorizeRole } from '@/auth/utils'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { avatarText } from '@core/utils/filter'
import client from '@/libs/http/axios-config'

export default {
  components: {
    BFormInvalidFeedback,
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BFormInput,
    BFormTextarea,
    BSpinner,
    BInputGroupAppend,
    BInputGroup,
  },
  data() {
    return {
      // State
      isLoading: false,
      blobImage: '',

      // Form Error
      formErrors: {
        schoolName: { state: null, message: '' },
        schoolAddress: { state: null, message: '' },
        schoolPhoneNumber: { state: null, message: '' },
        schoolLogo: { state: null, message: '' },
        schoolNpsn: { state: null, message: '' },
        adminEmail: { state: null, message: '' },
        adminPassword: { state: null, message: '' },
        adminConfirmPassword: { state: null, message: '' },
      },

      // Form Data
      formData: {
        schoolName: '',
        schoolAddress: '',
        schoolPhoneNumber: '',
        schoolLogo: '',
        schoolNpsn: '',
        adminEmail: '',
        adminPassword: '',
        adminConfirmPassword: '',
      },
    }
  },
  computed: {
    passwordFieldType() {
      return this.showPassword ? 'text' : 'password'
    },
    passwordToggleIcon() {
      return this.showPassword ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  mixins: [togglePasswordVisibility],
  methods: {
    avatarText,
    onFileChange(e) {
      const file = e.target.files[0]

      // adding into form data
      this.formData.schoolLogo = file
      this.formData.adminAvatar = file

      // create temporary url
      this.blobImage = URL.createObjectURL(file)
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    validateForm() {
      let isValid = true
      return isValid
    },
    async submitForm() {
      this.isLoading = true
      if (this.validateForm()) {
        const data = new FormData()

        data.append('schoolName', this.formData.schoolName)
        data.append('schoolAddress', this.formData.schoolAddress)
        data.append('schoolPhoneNumber', this.formData.schoolPhoneNumber)
        data.append('schoolLogo', this.formData.schoolLogo)
        data.append('schoolNpsn', this.formData.schoolNpsn)
        data.append('adminName', `Admin ${this.formData.schoolName}`)
        data.append('adminEmail', this.formData.adminEmail)
        data.append('adminPassword', this.formData.adminPassword)
        data.append('adminPhone', this.formData.schoolPhoneNumber)
        data.append('adminAvatar', this.formData.schoolLogo)
        data.append('adminAddress', this.formData.schoolAddress)

        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }

        // create classroom
        await client
          .post(`/admin/schools`, data, config)
          .then((response) => {
            // stop loading
            this.isLoading = false

            // show success toast
            this.showToast(
              'success',
              'Check',
              'Berhasil',
              'Sekolah berhasil dibuat'
            )

            // redirect to classroom detail
            this.$router.push({
              name: checkAuthorizeRole('schools'),
            })
          })
          .catch((error) => {
            console.error(error)
            this.isButtonLoading = false

            this.showToast(
              'error',
              'XIcon',
              'Gagal',
              error.response.data.message
            )
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.line-height-small {
  line-height: 1.5rem;
}

.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.pointer {
  cursor: pointer;
}

.is-invalid {
  border-color: #ea5455 !important;
}
</style>
